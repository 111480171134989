"use client";

import { PropsWithChildren } from "react";

interface IProvidersProps {}

const Providers: React.FC<PropsWithChildren<IProvidersProps>> = ({ children }) => {
	return <>{children}</>;
};
export default Providers;
