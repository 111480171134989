"use client";

import { useEffect } from "react";

interface IConsoleProps {}

interface IWindowWithEpic {
	Epic: { (): void };
}

const Console: React.FC<IConsoleProps> = () => {
	useEffect(() => {
		if (window.console) {
			const setStyle = function (isItalicBold: boolean, color: string) {
				return `font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; font-size: 15px; ${
					isItalicBold ? "font-style:italic; font-weight:bold; " : ""
				} color: ${color};`;
			};
			console.log(
				`%cWant to do Something %cEpic()%c?`,
				setStyle(false, "#888"),
				setStyle(true, "#d22"),
				setStyle(false, "#888"),
			);
			(window as unknown as IWindowWithEpic).Epic = (): void => {
				window.location.href = "https://careers.epic.com?js";
			};
		}
	}, []);
	return <></>;
};

export default Console;
