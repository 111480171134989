"use client";

import { usePathname } from "next/navigation";
import { useEffect } from "react";

interface IToTopProps {}
const ToTop: React.FC<IToTopProps> = () => {
	const pathname = usePathname();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return <></>;
};

export default ToTop;
